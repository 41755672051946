<!-- /* eslint-disable */-->
<template>
  <keyword-list
      searchType="rel"
      menuText="연관검색어"
  />
</template>

<script>
import KeywordList from '@/components/KeywordList.vue'

export default {
  components: {KeywordList}
}
</script>