<!-- /* eslint-disable */-->
<template>
    <v-container>
      <!-- Main Title -->
      <v-row no-gutters>
        <v-col cols="12">
          <h1>키워드 검색량 조회</h1>
        </v-col>
        <v-col cols="12" style="margin:20px 0;">
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header>가이드</v-expansion-panel-header>
              <v-expansion-panel-content>
                - 키워드 검색량 정보를 조회할 수 있습니다.<br>
                - 3개월 이상 경과한 이력은 삭제될 수 있습니다.<br>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
  
      <!-- Collapsible Description -->
      <v-row no-gutters>
        
        <v-col cols="12" style="text-align:right; margin:10px 0;">
          <v-btn style="margin-right:5px;" color="primary" dense @click="openInputDialog">
            등록
          </v-btn>
        </v-col>
      </v-row>
      <!-- Table (takes the remaining space) -->
      <v-row no-gutters class="flex-grow-1">
        <v-col cols="12" style="text-align:right; margin:10px 0;">
        </v-col>
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="items"
            item-key="no"
            :items-per-page="9999"
            show-select
            v-model:selected="selected"
            hide-default-footer
          >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>키워드 검색 목록</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="searchText"
              label="키워드 명"
              outlined
              dense
              hide-details
              style="max-width: 250px; margin-right: 10px;"
              @keyup.enter="getContents"
            ></v-text-field>
            <v-btn color="primary" @click="getContents" style="margin-right: 10px;">검색</v-btn>
          </v-toolbar>
        </template>
        
          <template v-slot:body="{ items }">
            <tbody>
              <template v-for="item in items">
                <tr :key="item.no" class="text-center">
                  <td>
                    <v-checkbox
                      v-model="selected"
                      :value="item"
                    ></v-checkbox>
                  </td>
                  <td>{{ item.keyword }}</td>
                  <td>{{ parseInt(item.monthSearchCntMobile).toLocaleString() }}</td>
                  <td>{{ parseInt(item.monthSearchCntPc).toLocaleString() }}</td>
                  <td>{{ (parseInt(item.monthSearchCntPc) + parseInt(item.monthSearchCntMobile)).toLocaleString() }}</td>
                  <td>{{ parseFloat(item.monthClickCntMobile).toLocaleString() }}</td>
                  <td>{{ parseFloat(item.monthClickCntPc).toLocaleString() }}</td>
                  <td>{{ parseFloat(item.monthClickRateMobile).toLocaleString() }}</td>
                  <td>{{ parseFloat(item.monthClickRatePc).toLocaleString() }}</td>
                  <td>{{ item.competition }}</td>
                  <td>{{ parseInt(item.monthAdsCnt).toLocaleString() }}</td>
                  <td>{{ item.createTime.substring(0,16) }}</td>
                  <td>
                    <v-btn style="margin-top:5px; color:#FFF; margin-right: 5px;" small color="red" @click="deleteKeyword(item)">삭제</v-btn>
                  </td>
                </tr>
              </template>
            </tbody>
          </template>
          </v-data-table>
        </v-col>
      </v-row>
  
      <!-- 단건 추가 Dialog -->
      <v-dialog v-model="inputDialog" persistent max-width="500px">
        <div ref="report">
          <v-card>
            <v-card-title>
              <span class="text-h5">키워드 검색량 조회</span>
            </v-card-title>

            <v-card-text>
              <v-form>
                <!-- Input Field 1 -->
                <v-text-field label="키워드" v-model="input_keyword"></v-text-field>
              </v-form>
            </v-card-text>

            <v-card-actions style="text-align: right;">
              <!-- Button to close Dialog -->
              <v-spacer></v-spacer>
              <!-- Cancel button -->
              <v-btn color="secondary" text @click="inputDialog = false">취소</v-btn>
              <!-- Add button -->
              <v-btn color="primary" @click="addEntry()" :disabled="inputProgress">추가</v-btn>
            </v-card-actions>
          </v-card>
        </div>
      </v-dialog>
      <v-snackbar
        v-model="snackbar"
        color="primary"
        variant="tonal"
        :timeout="2000"
      >
        {{ snackText }}
      </v-snackbar>
      <report-component ref="reportComponent"></report-component>
      
    </v-container>
  </template>
  
  <script>
  import { wrapFetch } from '@/config';
  import ReportComponent from '/src/components/PlaceReport'
  
  export default {
    components: {ReportComponent},
    data() {
      return {
        snackbar: false,
        snackText: "",
        selected: [],
        headers: [
          { text: '검색 키워드', value: 'keyword', align: 'center'},
          { text: '월 검색수(PC)', value: 'placeUrl', align: 'center'},
          { text: '월 검색수(모바일)', value: 'name', align: 'center'},
          { text: '검색 수 합계', value: 'placeCount', align: 'center'},
          { text: '월 클릭수(PC)', value: 'monthSearchCntPc', align: 'center'},
          { text: '월 클릭수(Mobile)', value: 'monthSearchCntMobile', align: 'center'},
          { text: '월 클릭률(PC)', value: 'create_time', align: 'center'},
          { text: '월 클릭률(Mobile)', value: 'collectTime', align: 'center'},
          { text: '경쟁 정도', value: 'collectTime', align: 'center'},
          { text: '월 평균 노출 광고 수', value: 'collectTime', align: 'center'},
          { text: '검색 시간', value: 'collectTime', align: 'center'},
          { text: '', value: 'utillity', align: 'center'},
        ],
        inputDialog: false,
        inputProgress: false,
        input_keyword: '',
        searchText: "",
        items: [],
      };
    },
    methods: {
      addEntry(){
        let self = this;
        self.inputProgress = true;
        
        wrapFetch('/keyword', "POST", {
          keyword: this.input_keyword
        })
        .then(() => {
          self.snackText = "키워드 검색량 조회가 완료되었습니다.";
          self.snackbar = true;
          self.inputDialog = false;
          self.inputProgress = false;
          self.getContents();
        }).catch(() => {
          self.inputProgress = false;
        })
      },
      getContents(){
        let self = this;
        self.items = [];
        self.selected = [];
        
        wrapFetch(`/keyword?searchText=${self.searchText}`, "GET")
        .then((data) => {
          data.data.map((e) => {
            e.loading = false;
            self.items.push(e);
          })
        })
      },
      openInputDialog(){
        this.input_keyword = "";
        this.inputDialog = true;
      },
      deleteKeyword(item) {
        if(confirm(`${item.keyword} 키워드를 정말 삭제하시겠습니까?`)){
          wrapFetch(`/keyword?no=${item.no}`, "DELETE")
          .then(() => {
            self.snackText = "키워드 삭제가 완료되었습니다.";
            self.snackbar = true;
            this.getContents();
          })
        }
      },
    },
    mounted() {
      this.getContents();
    }
  };
  </script>
  
  <style>
  .flex-grow-1 {
    flex-grow: 1;
  }
  v-container.fill-height {
    height: 100vh;
  }
  .v-data-table__wrapper > table > tbody > tr:hover {
    background: inherit !important;
  }
  .blogCafeReviewCount {
    font-size: 0.9em;
    color: #555;
    font-weight: bold;
  }
  .visitorReviewCount {
    font-size: 0.9em;
    color: #633;
    font-weight: bold;
  }
  .saveCount {
    font-size: 0.9em;
    color: chocolate;
    font-weight: bold;
  }
  
  </style>