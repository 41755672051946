<template>
  <v-container>
    <!-- 그룹 관리 테이블 -->
     
    <v-row no-gutters>
      <v-col cols="12">
        <h1>[관리자] 사용자 관리</h1>
      </v-col>
      <v-col cols="12" style="margin:20px 0;">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>가이드</v-expansion-panel-header>
            <v-expansion-panel-content>
              - 사용자를 관리하는 화면입니다.<br>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <!-- Collapsible Description -->
    <v-row no-gutters>
    </v-row>
    <!-- Table (takes the remaining space) -->
    <v-row no-gutters class="flex-grow-1">
      <v-col cols="12" style="text-align:right; margin:10px 0;">
        <v-btn color="primary" @click="openDialog(null)">사용자 추가</v-btn>
      </v-col>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="items"
          class="elevation-1"
          hide-default-footer
          :page.sync="page"
          :items-per-page="pagePer"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <!-- 좌측 타이틀 -->
              <v-toolbar-title>사용자 목록</v-toolbar-title>
              <span class="ml-3"> 사용자 수 : {{ totalCount }}</span>
              <!-- 우측 검색 및 그룹 추가 영역 -->
              <v-spacer></v-spacer>
              
              <!-- 검색 필드와 버튼을 정렬 -->
              <v-text-field
                v-model="searchText"
                label="사용자 ID"
                outlined
                dense
                hide-details
                style="max-width: 250px; margin-right: 10px;"
                @keyup.enter="fetchNotice"
              ></v-text-field>
              <v-btn color="primary" @click="fetchNotice" style="margin-right: 10px;">검색</v-btn>
            </v-toolbar>
            <div class="d-flex flex-column justify-end align-content-end mr-3" style="align-items: end;" >
              <span class="mr-5">페이지 당 조회수</span>
              <v-select
                  v-model="pagePer"
                  :items="pagePerItems"
                  outlined
                  dense
                  hide-details
                  return-object
                  single-line
                  style="max-width: 150px;"
                  class="custom-select"
                  @change="changePagePer"
              ></v-select>
            </div>
          </template>
          <template v-slot:item="{ item }">
            <tr class="text-center">
              <td> {{item.no}} </td>
              <td> {{item.id}} </td>
              <td> {{item.company}} </td>
              <td> {{item.email}} </td>
              <td> {{item.telNo}} </td>
              <td> {{item.acceptsMarketing}} </td>
              <td> {{item.useDate}} </td>
              <td> {{item.useSlot}} </td>
              <td>{{ item.authLevel == '1' ? '사용자' : '관리자' }}</td>
              <td>
                <v-btn style="margin-top:5px; color:#FFF; margin-right: 5px;" small color="#1e9ae5" @click="openDialog(item)">수정</v-btn>
                <v-btn style="margin-top:5px; color:#FFF; margin-right: 5px;" small color="red" @click="deletegroup(item)">삭제</v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
        <v-pagination v-model="page" :length="pageCount" @input="fetchNotice"></v-pagination>
      </v-col>
    </v-row>

    <!-- 사용자 추가/수정 Dialog -->
    <v-dialog v-model="dialog" max-width="1024px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ selectedItem ? '사용자 수정' : '사용자 추가' }}</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-text-field
              label="사용자 ID"
              outlined
              v-model="formData.id"
            ></v-text-field>
            <v-text-field
              label="사용자 비밀번호 ( 가입 or 변경 필요시만 입력 )"
              outlined
              v-model="formData.pw"
            ></v-text-field>
            <v-text-field
              label="회사명"
              outlined
              v-model="formData.company"
            ></v-text-field>
            <v-text-field
              label="이메일"
              outlined
              v-model="formData.email"
            ></v-text-field>
            <v-text-field
              label="전화번호"
              outlined
              v-model="formData.telNo"
            ></v-text-field>
            <v-text-field
              label="사용 기한"
              outlined
              v-model="formData.useDate"
            ></v-text-field>
            <v-text-field
              label="사용 가능 슬롯"
              outlined
              v-model="formData.useSlot"
            ></v-text-field>
            <v-text-field
              label="사용자 권한 ( 1: 사용자 / 2: 관리자 ) 숫자만 입력"
              outlined
              v-model="formData.authLevel"
            ></v-text-field>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeDialog">취소</v-btn>
          <v-btn color="primary" @click="savegroup">저장</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar"
      color="primary"
      variant="tonal"
      :timeout="2000"
    >
      {{ snackText }}
    </v-snackbar>
  </v-container>
</template>

<script>
import { wrapFetch } from '@/config'

export default {
  data() {
    return {
      dialog: false,
      selectedItem: null,
      formData: {
        no: '',
        id: '',
        pw: '',
        company: '',
        email: '',
        useDate: '',
        useSlot: '',
        authLevel: ''
      },
      headers: [
        { text: 'No', value: 'no', align: 'center'},
        { text: 'Id', value: 'id', align: 'center'},
        { text: '회사명', value: 'company', align: 'center'},
        { text: '이메일', value: 'email', align: 'center'},
        { text: '전화번호', value: 'telNo', align: 'center'},
        { text: '마케팅 수신 동의 여부', value: 'acceptsMarketing', align: 'center'},
        { text: '사용 기한', value: 'useDate', align: 'center'},
        { text: '사용 가능 슬롯', value: 'useSlot', align: 'center'},
        { text: '권한', value: 'authLevel', align: 'center'},
        { text: '', value: 'actions', sortable: false },
      ],
      items: [], // 서버에서 받아올 사용자 목록
      snackbar: false,
      snackText: '',
      searchText: '', // 검색어 입력
      pagePerItems: [5, 10, 20, 50],
      totalCount: 0,
      page: 1,
      pagePer: 50,
    };
  },
  computed: {
    pageCount() {
      return Math.ceil(this.totalCount / this.pagePer)
    },
  },
  methods: {
    changePagePer() {
      this.page = 1;
      this.fetchNotice();
    },
    openDialog(item) {
      if (item) {
        this.selectedItem = item;
        this.formData = { ...item };
      } else {
        this.selectedItem = null;
        this.resetFormData();
      }
      this.dialog = true;
    },

    closeDialog() {
      this.dialog = false;
    },

    resetFormData() {
      this.formData = {
        no: '',
        id: '',
        pw: '',
        company: '',
        email: '',
        useDate: '',
        useSlot: '',
        authLevel: ''
      };
    },

    async savegroup() {
      let self = this;
      if (this.selectedItem) {
        wrapFetch("/usermng", "PUT", this.formData)
        .then(() => {
          self.snackText = "사용자 수정이 완료되었습니다.";
          self.snackbar = true;
          self.dialog = false;
          self.resetFormData();
          self.fetchNotice();
        })
      } else {
        wrapFetch("/usermng", "POST", this.formData)
        .then(() => {
          self.snackText = "사용자 추가가 완료되었습니다.";
          self.snackbar = true;
          self.dialog = false;
          self.resetFormData();
          self.fetchNotice();
        })
      }
    },
    async deletegroup(item) {
      if(confirm(`${item.id} 사용자를 정말 삭제하시겠습니까?`)){
        wrapFetch(`/usermng?no=${item.no}`, "DELETE")
        .then(() => {
          self.snackText = "사용자 삭제가 완료되었습니다.";
          self.snackbar = true;
          this.fetchNotice();
        })
      }
      
    },
    async fetchNotice() {
      let self = this;
      wrapFetch(`/usermng?searchText=${self.searchText}&page=${self.page}&pagePer=${self.pagePer}`, "GET")
      .then(e => {
        self.totalCount = e.totalCount
        self.items = [];
        self.items.push(...e.data);
      })
    },
  },
  mounted() {
    this.fetchNotice();
  },
};
</script>

<style scoped>
.mb-4 {
  margin-bottom: 16px;
}
</style>
