<template>
  <place-list
    placeTenant="1"
    menuText="N사 플레이스 순위 확인"
  />
</template>
<script>
import PlaceList from '/src/components/PlaceList.vue'

export default {
  components: {PlaceList},
}
</script>