<template>
  <v-dialog class="reportDialog" v-model="reportDialog" max-width="1000px">
    <div class="container" v-if="!loading" style="text-align:right">
      <div class="date-setter pe-4 mt-5">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="dates"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              v-bind="attrs"
              v-on="on">조회 기간 변경</v-btn>
          </template>
          <v-date-picker
            v-model="dates"
            range
            no-title
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @input="checkDateRules"
              @click="menu = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="research()"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
        <div class="mt-1" style="font-size:11px;">*최대 30일까지 조회 가능합니다.</div>
      </div>
    </div>
    <div class="container" v-if="loading" style="text-align:center;">
      보고서 생성중<br>
      <v-progress-circular :size="100" :width="10"  indeterminate ></v-progress-circular>
    </div>
    <div class="container px-2" v-else ref="report">
      <!-- 상단 정보 섹션 -->
      <div class="section">

        <div class="title pt-5">플레이스 순위 체크 브랜딩 비교 보고서</div>
        <div style="font-weight:500; font-size:13px; color:#b51010" class="mb-1 text-right">
          {{ dates[0] }} ~ {{ dates[1] }}
        </div>
<!--        <div class="section-title text-center my-1">-->
<!--          기본 정보-->
<!--          <div class="text-right" style="font-weight:500; font-size:13px; color:#b51010">-->
<!--            {{ dates[0] }} ~ {{ dates[1] }}-->
<!--          </div>-->
<!--        </div>-->
        <div class="d-flex mb-1 mt-5">
          <span class="flex-grow-0" style="width:30%;">키워드</span>
          <span class="flex-grow-1 font-weight-medium">{{ place.keyword }}</span>
        </div>
        <div class="d-flex mb-1">
          <span class="flex-grow-0" style="width:30%;">업체명(URL)</span>
          <span class="flex-grow-1 font-weight-medium">{{ place.name }} ( {{ place.placeUrl }} )</span>
        </div>
        <div class="d-flex mb-1">
          <span class="flex-grow-0" style="width:30%;">월 조회수(PC / Mobile)</span>
          <span class="flex-grow-1 font-weight-medium">
            {{ isNaN(parseInt(place.monthSearchCntPc)) ? place.monthSearchCntPc : parseInt(place.monthSearchCntPc).toLocaleString() }} 건
              /
            {{ isNaN(parseInt(place.monthSearchCntMobile)) ? place.monthSearchCntMobile : parseInt(place.monthSearchCntMobile).toLocaleString() }} 건</span>
        </div>
        <div class="d-flex mb-1">
          <span class="flex-grow-0" style="width:30%;">업체 수</span>
          <span class="flex-grow-1 font-weight-medium">{{ parseInt(place.placeCount).toLocaleString() }} 개</span>
        </div>
      </div>

      <!-- 날짜별 통계 정보 -->
      <v-row class="mx-0 mt-3 mb-5" style="width:calc(100% - 4px);">
        <v-col
            style="
                padding: 5px;
                text-align:center;
                -webkit-box-flex: 0;
                -ms-flex: 0 0 11.111111%;
                flex: 0 0 11.111111%;
                max-width: 11.111111%;
              "
            v-for="rankItem in place.rankList" v-bind:key="JSON.stringify(rankItem)"
        >
          <div style="border-bottom:1px solid #ccc; font-weight:500;font-size:0.9em;">{{rankItem.date}}</div>
          <span style="font-size:0.8em;" class="rankCount">{{rankItem.rank}}위<br> </span>
          <span class="saveCount" v-if="place.placeType == 'restaurant'">저 {{ rankItem.save_count }}<br></span>
          <span class="blogCafeReviewCount">블 {{ rankItem.blog_cafe_review_count }}</span><br>
          <span class="visitorReviewCount">방 {{ rankItem.visitor_review_count }}</span><br>
        </v-col>
      </v-row>

      <!-- 차트 섹션 -->
      <div class="section">
        <div class="section-title my-2">순위 추이 차트</div>
        <div class="chart">
          <line-chart :data="chartData1" :options="chartOptions(1)" style="width:100%; height:100%;"/>
        </div>
      </div>

      <div class="section" v-if="place.placeType == 'restaurant'">
        <div class="section-title my-2">저장수 추이 차트</div>
        <div class="chart">
          <line-chart :data="chartData2" :options="chartOptions(2)" style="width:100%; height:100%;"/>
        </div>
      </div>

      <div class="section">
        <div class="section-title my-2">블로그 방문자 리뷰수 추이 차트</div>
        <div class="chart">
          <line-chart :data="chartData3" :options="chartOptions(3)" style="width:100%; height:100%;"/>
        </div>
      </div>
      <hr>
      <!-- 푸터 -->
      <div class="footer">
        <div style="display:inline-block; width:49%; text-align:left">{{ company }}</div>
        <div style="display:inline-block; width:49%; text-align:right">{{ currentTime }}</div>
      </div>
    </div>
    
    <div class="topContainer" style="padding: 20px;" v-if="!loading"> 
      다운로드 : 
      <v-btn color="red" style="margin-left:10px;" @click="exportAsPDF">PDF 다운로드</v-btn>
      <v-btn color="primary" style="margin-left:10px;" @click="exportAsImage">이미지 다운로드</v-btn>
    </div>
  </v-dialog>
</template>
  
<script>
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { wrapFetch } from '@/config';
import { Line } from 'vue-chartjs';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(Title, Tooltip, Legend, LineElement, PointElement, LinearScale, CategoryScale, ChartDataLabels);

export default {
  components: {
    LineChart: Line
  },
  data() {
    return {
      place: {},
      reportDialog: false,
      currentTime: '',
      loading: true,
      menu: false,
      item: null,
      dates: [],
      company: "",
      chartData1: {
        labels: [],
        datasets: []
      },
      chartData2: {
        labels: [],
        datasets: []
      },
      chartData3: {
        labels: [],
        datasets: []
      },
    };
  },
  methods: {
    loadReport(item, checkStartDate){
      let self = this;
      self.item = item;
      self.loading = true;
      self.reportDialog = true;
      //reset
      self.chartData1 = {
        labels: [],
        datasets: []
      };
      self.chartData2 = {
        labels: [],
        datasets: []
      };
      self.chartData3 = {
        labels: [],
        datasets: []
      };
      self.place = {};
      if(checkStartDate){
        const createTime = new Date(item.createTime);
        const currentDate = new Date();

        // 현재 년도와 월을 비교
        if (createTime.getFullYear() === currentDate.getFullYear() &&
            createTime.getMonth() === currentDate.getMonth()) {
          self.dates[0] = item.createTime;
        }else{
          currentDate.setDate(1);  // 이번 달 1일로 설정
          self.dates[0] = currentDate.toISOString().split('T')[0];  // "YYYY-MM-DD" 형식으로 변환
        }
      }
      wrapFetch(`/place/${item.no}?fromDate=${self.dates[0]}&toDate=${self.dates[1]}`, "GET").then( e => {
        //place info setting
        self.place = e.place;

        // 날짜 형식이 "MM-DD"이므로, 이를 "MM-DD" 문자열로 정렬합니다.
        self.place.rankList.sort((a, b) => {
          const [monthA, dayA] = a.date.split('-').map(num => parseInt(num, 10));
          const [monthB, dayB] = b.date.split('-').map(num => parseInt(num, 10));
          
          // 월을 기준으로 먼저 비교하고, 월이 같으면 일(day)을 기준으로 비교
          if (monthA !== monthB) {
            return monthA - monthB; // 월 기준 오름차순
          }
          return dayA - dayB; // 일 기준 오름차순
        });

        //chart setting
        this.chartData1.labels = self.place.rankList.map(e => e.date);
        this.chartData2.labels = self.place.rankList.map(e => e.date);
        this.chartData3.labels = self.place.rankList.map(e => e.date);
        
        this.chartData1.datasets.push(
          {
            label: '순위',
            data: self.place.rankList.map(e => {
              let data = parseInt(e.rank.replaceAll("+", "").replaceAll(",", "").replaceAll("-", ""));
              if(data == 0) data = NaN;
              return data;
            }),
            borderColor: 'rgba(75, 192, 192, 1)',
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            borderWidth: 2,
            spanGaps: true,
          }
        )
        
        this.chartData2.datasets.push(
          {
            label: '저장수',
            data: self.place.rankList.map(e => {
              let data = parseInt(e.save_count.replaceAll("+", "").replaceAll(",", "").replaceAll("-", ""));
              if(data == 0) data = NaN;
              return data;
            }),
            borderColor: 'rgba(210, 105, 31, 1)',
            backgroundColor: 'rgba(210, 105, 31, 0.2)',
            borderWidth: 2,
            spanGaps: true,
          }
        )

        this.chartData3.datasets.push(
          {
            label: '블로그 리뷰수',
            data: self.place.rankList.map(e => {
              let data = parseInt(e.blog_cafe_review_count.replaceAll("+", "").replaceAll(",", "").replaceAll("-", ""));
              if(data == 0) data = NaN;
              return data;
            }),
            borderColor: 'rgba(102, 51, 51, 1)',
            backgroundColor: 'rgba(102, 51, 51, 0.2)',
            borderWidth: 2,
            spanGaps: true,
          }
        )

        this.chartData3.datasets.push(
          {
            label: '방문자 리뷰수',
            data: self.place.rankList.map(e => {
              let data = parseInt(e.visitor_review_count.replaceAll("+", "").replaceAll(",", "").replaceAll("-", ""));
              if(data == 0) data = NaN;
              return data;
            }),
            borderColor: 'rgba(85, 85, 85, 1)',
            backgroundColor: 'rgba(85, 85, 85, 0.2)',
            borderWidth: 2,
            spanGaps: true,
          }
        )

        this.loading = false;
      }).catch( () => {
        self.loading = false;
      })
    },
    research() {
      this.checkDateRules()
      this.menu = false;
      this.loadReport(this.item, false);
    },
    async exportAsImage() {
      const element = this.$refs.report;
      const canvas = await html2canvas(element);
      const image = canvas.toDataURL('image/png');

      // 이미지 다운로드
      const link = document.createElement('a');
      link.href = image;
      link.download = 'exported-image.png';
      link.click();
    },
    async exportAsPDF() {
      const element = this.$refs.report;
      const canvas = await html2canvas(element);
      const pdf = new jsPDF('p', 'mm', 'a4');

			var imgData = canvas.toDataURL('image/png');
			var imgWidth = 210;
			var pageHeight = 295;
			var imgHeight = canvas.height * imgWidth / canvas.width;
			var heightLeft = imgHeight;
			var position = 0;
			pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight); 
			heightLeft -= pageHeight;
			while (heightLeft >= 0) {
				position = heightLeft - imgHeight;
				pdf.addPage();
				pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
				heightLeft -= pageHeight;
      }

      pdf.save('exported-document.pdf');
    },
    checkDateRules() {
      if (this.dates.length === 2) {

        if (new Date(this.dates[0]) > new Date(this.dates[1])) {
          // 시작 날짜가 종료 날짜보다 클 경우 서로 교환
          this.dates = [this.dates[1], this.dates[0]];
        }
      }
    }
  },
  mounted(){
    let today = new Date();   
    const todayKST = new Date(today.getTime() + (9 * 60 * 60 * 1000));
    let minDate = new Date(todayKST);
    minDate.setDate(todayKST.getDate() - 30);
    this.dates.push(minDate.toISOString().split('T')[0]);
    this.dates.push(todayKST.toISOString().split('T')[0]);
    this.currentTime = todayKST.toISOString().split('T')[0];
    this.checkDateRules()
    this.company = JSON.parse(sessionStorage.getItem("loginInfo")).company;
  },
  computed: {
    chartOptions() {
      return (type) => {
        const allData = this["chartData" + type].datasets.flatMap(dataset => dataset.data);
        const minValue = Math.min(...allData);
        const maxValue = Math.max(...allData);

        return {
          responsive: true,
          scales: {
            y: {
              reverse: type === 1,
              min: minValue - ((maxValue - minValue) * 0.2), // 차이값의 20% 여백
              max: maxValue + ((maxValue - minValue) * 0.2), // 차이값의 20% 여백
            },
          },
          plugins: {
            legend: {
              display: true,
              position: 'top',
            },
            tooltip: {
              enabled: true,
            },
            datalabels: {
              align: 'end',
              anchor: 'start',
              display: true,
              color: 'black',
              backgroundColor: 'rgba(220, 220, 220, 0.7)', // 배경 색상
              borderRadius: 4, // 모서리를 둥글게 설정
              borderWidth: 2,
              font: {
                weight: 'bold',
              },
              formatter: (value) => parseInt(value) >= 10000 ? (parseInt(value)/10000).toFixed(1) + "만" : parseInt(value).toLocaleString(),
            },
          },
        };
      }
    },
  }
};
</script>

<style scoped>
.reportDialog {
  background-color: #f9f9f9 !important;
}
body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  margin: 20px;
}
.topContainer{
  width: 100%;
  max-width: 85vw;
  margin: 0 auto;
  background-color: #f9f9f9;
}
.container {
  width: 100%;
  max-width: 85vw;
  margin: 0 auto;
  padding: 0;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
}
.title {
  text-align: center;
  font-size: 27px;
  font-weight: 600;
}
.section {
  margin-bottom: 30px;
}
.section-rank {
  border:1px solid #ccc;
}
.section-title {
  font-size: 18px;
  font-weight: bold;
}
.info-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.info-label {
  font-weight: bold;
}
.info-value {
  text-align: right;
}
.chart {
  height: 250px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer {
  text-align: center;
  color: #777;
  font-size: 12px;
}
.top {
  text-align: right;
  color: #777;
  font-size: 12px;
  padding: 0px !important;
}
.rankCount{
  font-weight: bold;
}
.blogCafeReviewCount {
  font-size: 0.8em;
  color: #3f51b5;
  font-weight: bold;
}
.visitorReviewCount {
  font-size: 0.8em;
  color: #F9A825;
  font-weight: bold;
}
.saveCount {
  font-size: 0.8em;
  color: #BF360C;
  font-weight: bold;
}
</style>
