<!-- /* eslint-disable */-->
<template>
  <keyword-list
      searchType="auto"
      menuText="자동완성 검색"
  />
</template>

<script>
import KeywordList from '@/components/KeywordList.vue'

export default {
  components: {KeywordList}
}
</script>