<template>
    <v-container class="fill-height">
      <v-row justify="center" align="center">
        <v-col cols="12" md="8" class="text-center">
          <v-img src="https://cdn.vuetifyjs.com/images/logos/logo.svg" max-width="300" class="mb-6"></v-img>
          <v-slide-y-transition>
            <h1 class="error-code">404</h1>
          </v-slide-y-transition>
          <p class="error-message mb-4">
            페이지가 존재하지 않습니다.
          </p>
          <v-btn color="primary" @click="goHome">
            메인으로 돌아가기
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  export default {
    name: 'NotFoundPage',
    methods: {
      goHome() {
        this.$router.push('/');
      },
    },
  };
  </script>
  
  <style scoped>
  .error-code {
    font-size: 8rem;
    font-weight: bold;
    color: #3f51b5;
  }
  
  .error-message {
    font-size: 1.5rem;
    color: #757575;
  }
  </style>