<template>
  <place-list
      placeTenant="3"
      menuText="N사 예약 팬션 순위 확인"
  />
</template>

<script>
import PlaceList from '/src/components/PlaceList.vue'

export default {
  components: {PlaceList},
}
</script>