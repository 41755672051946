<template>
  <v-app id="inspire">
    <v-container class="fill-height d-flex align-center justify-center login-background">
      <v-row justify="center" align="center" style="margin-top:-10vh;">
        <v-col cols="12" align="center">
          <h1>나의 비서</h1>
        </v-col>
        <v-col cols="12" sm="8" md="4">
          <v-card class="pa-5" elevation="10">
            <v-card-title class="text-h5 text-center">로그인</v-card-title>
            <v-card-text>
              <v-form v-model="valid">
                <v-text-field
                  v-model="id"
                  label="아이디"
                  :rules="[rules.required]"
                  prepend-icon="mdi-email"
                  outlined
                ></v-text-field>
  
                <v-text-field
                  v-model="password"
                  label="비밀번호"
                  :type="showPassword ? 'text' : 'password'"
                  :rules="[rules.required, rules.min]"
                  prepend-icon="mdi-lock"
                  append-icon="mdi-eye"
                  @click:append="showPassword = !showPassword"
                  outlined
                ></v-text-field>
  
                <v-btn
                  :disabled="!valid"
                  color="primary"
                  class="mt-4"
                  block
                  @click="login"
                >
                  로그인
                </v-btn>
              </v-form>
            </v-card-text>
  
            <v-card-actions class="justify-center">
              <v-btn text @click="goToRegister">
                회원가입
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>
  
  <script>
  import { wrapFetch } from '@/config'
  export default {
    data() {
      return {
        id: '',
        password: '',
        showPassword: false,
        valid: false,
        rules: {
          required: (value) => !!value || '필수 입력.',
          email: (value) => /.+@.+\..+/.test(value) || '이메일 형식에 맞게 입력해주세요.',
          min: (value) => value.length >= 6 || '비밀번호는 6글자 이상이어야 합니다.',
        },
      };
    },
    mounted: () => {
        sessionStorage.clear();
    },
    methods: {
      async login() {
        if (this.valid) {
          // 로그인 로직 처리
          wrapFetch('/user/login', "POST", {
            id: this.id,
            pw: this.password
          })
          .then((data) => {
            sessionStorage.setItem("loginInfo", JSON.stringify(data.session));
            location.href = "/";
          })
        }
      },
      goToRegister() {
        // 회원가입 페이지로 이동
        this.$router.push('/register');
      },
    },
  };
  </script>
  
<style scoped>

html, body, #app, .v-application {
  background-color: #e3f2fd !important; /* 연한 파란색 배경 */
  height: 100%;
  margin: 0;
  padding: 0;
}

.v-application--wrap {
  min-height: 100vh;
  padding: 0;
  margin: 0;
}

.login-background {
  background-color: transparent; /* 내부 투명 처리 */
  padding: 0;
}

.v-card {
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15) !important; /* 더 강한 그림자 */
  border-radius: 10px;
  
  position: relative;
  z-index: 1;
}

.v-btn {
  font-weight: bold;
}

</style>