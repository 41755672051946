<template>
  <place-list
      placeTenant="2"
      menuText="N사 플레이스 저장 확인"
  />
</template>
<script>
import PlaceList from '/src/components/PlaceList.vue'

export default {
  components: {PlaceList},
}
</script>
