<template>
  <v-container>
    <!-- 그룹 관리 테이블 -->
     
    <v-row no-gutters>
      <v-col cols="12">
        <h1>그룹 관리</h1>
      </v-col>
      <v-col cols="12" style="margin:20px 0;">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>가이드</v-expansion-panel-header>
            <v-expansion-panel-content>
              - 그룹을 관리하는 화면입니다.<br>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <!-- Collapsible Description -->
    <v-row no-gutters>
    </v-row>
    <!-- Table (takes the remaining space) -->
    <v-row no-gutters class="flex-grow-1">
      <v-col cols="12" style="text-align:right; margin:10px 0;">
        <v-btn color="primary" @click="openDialog(null)">그룹 추가</v-btn>
      </v-col>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="groups"
          class="elevation-1"
          hide-default-footer
          :items-per-page="9999"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <!-- 좌측 타이틀 -->
              <v-toolbar-title>그룹 목록</v-toolbar-title>
              
              <!-- 우측 검색 및 그룹 추가 영역 -->
              <v-spacer></v-spacer>
              
              <!-- 검색 필드와 버튼을 정렬 -->
              <v-text-field
                v-model="searchText"
                label="그룹명"
                outlined
                dense
                hide-details
                style="max-width: 250px; margin-right: 10px;"
                @keyup.enter="fetchgroups"
              ></v-text-field>
              <v-btn color="primary" @click="fetchgroups" style="margin-right: 10px;">검색</v-btn>
            </v-toolbar>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-btn style="margin-top:5px; color:#FFF; margin-right: 5px;" small color="#1e9ae5" @click="openDialog(item)">수정</v-btn>
            <v-btn style="margin-top:5px; color:#FFF; margin-right: 5px;" small color="red" @click="deletegroup(item)">삭제</v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <!-- 사용자 추가/수정 Dialog -->
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ selectedGroup ? '그룹 수정' : '그룹 추가' }}</span>
        </v-card-title>

        <v-card-text>
          <v-form ref="form">
            <v-text-field
              label="그룹 명"
              outlined
              v-model="formData.name"
            ></v-text-field>
            <v-textarea
              label="메모"
              outlined
              v-model="formData.memo"
            ></v-textarea>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeDialog">취소</v-btn>
          <v-btn color="primary" @click="savegroup">저장</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar"
      color="primary"
      variant="tonal"
      :timeout="2000"
    >
      {{ snackText }}
    </v-snackbar>
  </v-container>
</template>

<script>
import { wrapFetch } from '@/config'
export default {
  data() {
    return {
      dialog: false,
      selectedGroup: null,
      formData: {
        name: '',
        memo: ''
      },
      headers: [
        { text: 'ID', value: 'groupNo' },
        { text: '그룹 명', value: 'name' },
        { text: '그룹 생성일', value: 'createTime' },
        { text: '플레이스 개수', value: 'placeCount' },
        { text: '메모', value: 'memo' },
        { text: '', value: 'actions', sortable: false },
      ],
      groups: [], // 서버에서 받아올 사용자 목록
      snackbar: false,
      snackText: '',
      searchText: '', // 검색어 입력
    };
  },
  methods: {
    openDialog(group) {
      if (group) {
        this.selectedgroup = group;
        this.formData = { ...group };
      } else {
        this.selectedgroup = null;
        this.resetFormData();
      }
      this.dialog = true;
    },

    closeDialog() {
      this.dialog = false;
    },

    resetFormData() {
      this.formData = {
        name: '',
        memo: ''
      };
    },

    async savegroup() {
      let self = this;
      if (this.selectedgroup) {
        wrapFetch("/group", "PUT", this.formData)
        .then(() => {
          self.snackText = "그룹 수정이 완료되었습니다.";
          self.snackbar = true;
          self.dialog = false;
          self.resetFormData();
          self.fetchgroups();
        })
      } else {
        wrapFetch("/group", "POST", this.formData)
        .then(() => {
          self.snackText = "그룹 추가가 완료되었습니다.";
          self.snackbar = true;
          self.dialog = false;
          self.resetFormData();
          self.fetchgroups();
        })
      }
    },
    async deletegroup(group) {
      if(confirm(`${group.name} 그룹을 정말 삭제하시겠습니까? 해당 그룹에 속한 플레이스는 '기본 그룹' 으로 강제 변경됩니다.`)){
        wrapFetch(`/group?groupNo=${group.groupNo}`, "DELETE")
        .then(() => {
          self.snackText = "그룹 삭제가 완료되었습니다.";
          self.snackbar = true;
          this.fetchgroups();
        })
      }
      
    },
    async fetchgroups() {
      let self = this;
      wrapFetch(`/group?searchText=${self.searchText}`, "GET")
      .then(e => {
        self.groups = [];
        self.groups.push(...e.group);
      })
    },
  },
  mounted() {
    this.fetchgroups();
  },
};
</script>

<style scoped>
.mb-4 {
  margin-bottom: 16px;
}
</style>
