<template>
  <v-app-bar app clipped-left clipped-right color="primary" dark>
    <v-toolbar-title class="align-center d-flex">
      나의 비서
    </v-toolbar-title>
    <v-app-bar-nav-icon
      @click="$vuetify.breakpoint.smAndDown ? setSidebarDrawer(!Sidebar_drawer) : $emit('input', !value)"
    />
    <v-spacer />
    <!---right part -->
    <v-btn @click="logout" text style="border: 1px solid #ffffff;" class="logout-btn">
      로그아웃
    </v-btn>
  </v-app-bar>
</template>
<script>
// Utilities
import { mapState, mapMutations } from "vuex";
export default {
  name: "Header",
  components: {},
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    href() {
      return undefined;
    }
  }),
  computed: {
    ...mapState(["Sidebar_drawer"])
  },

  methods: {
    ...mapMutations({
      setSidebarDrawer: "SET_SIDEBAR_DRAWER"
    }),
    logout(){
      this.$router.push("/login");
    }
  }
};
</script>
<style scoped>
.logout-btn:hover {
  background-color: #1976D2 !important;
  border-color: #E0E0E0 !important;
}
</style>