<template>
  <v-container fluid class="down-top-padding">

    <v-row no-gutters>
      <v-col cols="12">
        <h1>사용자 정보 수정</h1>
      </v-col>
      <v-col cols="12" style="margin:20px 0;">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>가이드</v-expansion-panel-header>
            <v-expansion-panel-content>
              - 사용자 정보를 수정하는 화면입니다.<br>
              - 비밀번호 수정은 버튼을 눌러 진행해 주세요.<br>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="12">
        <v-card>
          <v-divider></v-divider>
          <v-card-text>
            <v-form v-model="valid">
              <!-- 아이디는 수정 불가, 비활성화 -->
              <v-text-field
                v-model="user.id"
                label="아이디"
                filled
                background-color="transparent"
                disabled
              ></v-text-field>

              <!-- 이메일 -->
              <v-text-field
                type="email"
                v-model="user.email"
                label="이메일"
                filled
                background-color="transparent"
                :rules="[rules.required, rules.email]"
              ></v-text-field>

              <!-- 회사명 -->
              <v-text-field
                v-model="user.company"
                label="회사명"
                filled
                background-color="transparent"
                :rules="[rules.required]"
              ></v-text-field>

              <!-- 전화번호 -->
              <v-text-field
                v-model="user.telNo"
                label="전화번호"
                filled
                background-color="transparent"
                :rules="[rules.required]"
                @input="formatPhoneNumber"
              ></v-text-field>

              <!-- 만료일 (수정 불가) -->
              <v-text-field
                v-model="user.useDate"
                label="만료일"
                filled
                background-color="transparent"
                disabled
              ></v-text-field>

              <!-- 보유 슬롯 (수정 불가) -->
              <v-text-field
                v-model="user.useSlot"
                label="보유 슬롯"
                filled
                background-color="transparent"
                disabled
              ></v-text-field>

              <v-divider></v-divider>
              <div style="text-align:right;">
                <v-btn
                  class="text-capitalize mt-5 mr-5 element-0"
                  color="grey"
                  @click="showPasswordChangeModal = true"
                >
                  비밀번호 변경
                </v-btn>
                <v-btn
                  class="text-capitalize mt-5 element-0"
                  color="success"
                  @click="updateUserInfo"
                >
                  정보 수정
                </v-btn>
              </div>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- 비밀번호 변경 모달 -->
    <v-dialog v-model="showPasswordChangeModal" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">비밀번호 변경</span>
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-text-field
                type="password"
                v-model="newPassword"
                label="새 비밀번호"
                filled
                :rules="[rules.min]"
            ></v-text-field>
            <v-text-field
                type="password"
                v-model="confirmNewPassword"
                label="비밀번호 확인"
                filled
                :rules="[rules.min, matchNewPassword]"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" text @click="showPasswordChangeModal = false">
            취소
          </v-btn>
          <v-btn color="primary" text @click="updateUserPassword">
            변경
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { wrapFetch } from '../../config';

export default {
  name: "Profile",
  data: () => ({
    user: {
      id: '',         // 사용자 아이디
      email: '',      // 이메일
      company: '',    // 회사명
      telNo: '',      // 전화번호
      useDate: '',    // 만료일
      useSlot: '',    // 보유 슬롯
    },
    valid: false,
    showPasswordChangeModal: false,
    newPassword: '',
    confirmNewPassword: '',
    rules: {
      required: value => !!value || '필수 입력.',
      email: value => /.+@.+\..+/.test(value) || '이메일 형식에 맞지 않습니다.',
      min: value => (value.length >= 6 || value.length == 0) || '비밀번호는 6자 이상이어야 합니다.',
    }
  }),
  computed: {
    isPasswordValid() {
      return this.newPassword !== '' && this.confirmNewPassword !== '' && this.newPassword === this.confirmNewPassword;
    }
  },
  methods: {
    formatPhoneNumber() {
      // 숫자만 추출
      let cleaned = ('' + this.user.telNo).replace(/\D/g, '');
      
      // 3-4-4 형태로 변환
      let formatted = cleaned;
      if (cleaned.length > 3) {
        formatted = cleaned.slice(0, 3) + '-' + cleaned.slice(3);
      }
      if (cleaned.length > 7) {
        formatted = formatted.slice(0, 8) + '-' + cleaned.slice(7);
      }
      
      // 포맷팅된 값 설정
      this.user.telNo = formatted;
    },
    matchNewPassword(value) {
      return value === this.newPassword || '비밀번호가 일치하지 않습니다.';
    },
    updateUserInfo() {
      let self = this;
      if (this.valid) {
        // 사용자 정보 수정 로직 처리 (서버와 통신)
        if(confirm("정말 사용자 정보를 수정하시겠습니까?")){
          wrapFetch("/user", "POST", self.user)
          .then((data) => {
            alert(data.resultMessage);
          });
        }
      } else {
        alert("유효하지 않은 입력값이 있습니다.");
      }
    },
    updateUserPassword() {
      if (this.isPasswordValid) {
        let user = {
          id: this.user.id,
          pw: this.newPassword,
        }
        if (this.valid) {
          // 사용자 정보 수정 로직 처리 (서버와 통신)
          if(confirm("정말 사용자 정보를 수정하시겠습니까?")){
            wrapFetch("/user", "POST", user)
                .then((data) => {
                  alert(data.resultMessage);
                  this.showPasswordChangeModal = false
                  this.newPassword = ''
                  this.confirmNewPassword = ''
                });
          }
        }
      } else {
        alert("유효하지 않은 입력값이 있습니다.");
      }
    },
  },
  mounted(){
    let self = this;
    wrapFetch('/user', 'GET')
    .then((response) => {
      self.user.id = response.session.id;
      self.user.useDate = response.session.useDate;
      self.user.useSlot = parseInt(response.session.useSlot);
      self.user.usedSlot = parseInt(response.session.usedSlot);
      self.user.email = response.session.email;
      self.user.company = response.session.company;
      self.user.telNo = response.session.telNo;
    })
  }
};
</script>
