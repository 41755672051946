<template>
  <v-app id="inspire">
    <v-container class="fill-height d-flex align-center justify-center register-background">
      <v-col cols="12" sm="8" md="4">
        <v-card class="pa-5" elevation="10">
          <v-card-title class="text-h5 text-center">회원 가입</v-card-title>
          <v-card-text>
            <v-form v-model="valid">
              <v-text-field
                v-model="id"
                label="아이디"
                :rules="[rules.required]"
                prepend-icon="mdi-account"
                outlined
              ></v-text-field>

              <v-text-field
                v-model="password"
                label="비밀번호"
                :type="showPassword ? 'text' : 'password'"
                :rules="[rules.required, rules.min]"
                prepend-icon="mdi-lock"
                append-icon="mdi-eye"
                @click:append="showPassword = !showPassword"
                outlined
                autocomplete="new-password"
              ></v-text-field>

              <v-text-field
                v-model="confirmPassword"
                label="비밀번호 확인"
                :type="showPassword ? 'text' : 'password'"
                :rules="[rules.required, rules.matchPassword]"
                prepend-icon="mdi-lock"
                append-icon="mdi-eye"
                @click:append="showPassword = !showPassword"
                outlined
              ></v-text-field>

              <v-text-field
                v-model="company"
                label="업체 명"
                :rules="[rules.required]"
                prepend-icon="mdi-domain"
                outlined
              ></v-text-field>

              <v-text-field
                v-model="email"
                label="이메일"
                :rules="[rules.required, rules.email]"
                prepend-icon="mdi-email"
                outlined
              ></v-text-field>

              <v-text-field
                v-model="telNo"
                label="휴대전화 번호"
                :rules="[rules.required, rules.formattedPhone]"
                prepend-icon="mdi-phone"
                outlined
                @input="formatPhoneNumber"
                maxlength="13"
              ></v-text-field>

              <v-checkbox
                v-model="acceptsMarketing"
                label="이벤트, 혜택정보 수신동의(선택)"
                class="mt-4"
              ></v-checkbox>

              <v-btn
                :disabled="!valid"
                color="primary"
                class="mt-4"
                block
                @click="register"
              >
                회원가입
              </v-btn>
            </v-form>
          </v-card-text>

          <v-card-actions class="justify-center">
            <v-btn text @click="goToLogin">
              로그인 페이지로 돌아가기
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-container>
  </v-app>
</template>
  
<script>
import { wrapFetch } from '../../config';
export default {
  data() {
    return {
      id: '',
      password: '',
      confirmPassword: '',
      company: '',
      email: '',
      telNo: '',
      acceptsMarketing: false,
      showPassword: false,
      valid: false,
      rules: {
        required: (value) => !!value || '필수 입력.',
        email: (value) => /.+@.+\..+/.test(value) || '이메일 형식에 맞지 않습니다.',
        min: (value) => value.length >= 6 || '비밀번호는 6자 이상이어야 합니다.',
        matchPassword: (value) => value === this.password || '비밀번호가 일치하지 않습니다.',
        formattedPhone: (value) =>
          /^\d{3}-\d{3,4}-\d{4}$/.test(value) || '휴대전화 번호 형식이 맞지 않습니다.',
      },
    };
  },
  methods: {
    formatPhoneNumber() {
      // 숫자만 추출
      let cleaned = ('' + this.telNo).replace(/\D/g, '');
      
      // 3-4-4 형태로 변환
      let formatted = cleaned;
      if (cleaned.length > 3) {
        formatted = cleaned.slice(0, 3) + '-' + cleaned.slice(3);
      }
      if (cleaned.length > 7) {
        formatted = formatted.slice(0, 8) + '-' + cleaned.slice(7);
      }
      
      // 포맷팅된 값 설정
      this.telNo = formatted;
    },
    register() {
      if (this.valid) {
        // 회원가입 로직 처리
        let acceptsMarketingYN = this.acceptsMarketing ? 'Y' : 'N';
        let goLoginPage = this.goToLogin;
        wrapFetch("/user/register", "POST", {
          id: this.id,
          pw: this.password,
          company: this.company,
          email: this.email,
          telNo: this.telNo,
          acceptsMarketing: acceptsMarketingYN,
        })
        .then(function(data){
          alert(data.resultMessage);
          goLoginPage();
        })
      }
    },
    goToLogin() {
      this.$router.push('/login');
    },
  },
};
</script>
  
<style scoped>


html, body, #app, .v-application {
  background-color: #e3f2fd !important; /* 연한 파란색 배경 */
  height: 100%;
  margin: 0;
  padding: 0;
}

.v-application--wrap {
  min-height: 100vh;
  padding: 0;
  margin: 0;
}

.login-background {
  background-color: transparent; /* 내부 투명 처리 */
  padding: 0;
}

.v-card {
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15) !important; /* 더 강한 그림자 */
  border-radius: 10px;
  
  position: relative;
  z-index: 1;
}

.v-btn {
  font-weight: bold;
}
</style>