import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            redirect: 'pages/dashboard',
            component: () => import('@/layouts/Layout'),
            children: [
                // My Account
                {
                    name: 'Profile',
                    path: 'pages/profile',
                    component: () => import('@/views/pages/Profile'),
                },
                {
                    name: 'Dashboard',
                    path: 'pages/dashboard',
                    component: () => import('@/views/dashboard/BasicDashboard'),
                },
                {
                    name: 'GroupManagement',
                    path: 'pages/group',
                    component: () => import('@/views/pages/GroupManage'),
                },
                {
                    name: 'DeleteManagement',
                    path: 'pages/delete',
                    component: () => import('@/views/pages/PlaceDelete'),
                },
                
                // Place
                {
                    name: 'Place',
                    path: 'pages/place',
                    component: () => import('@/views/pages/Place'),
                },
                {
                    name: 'PlaceSave',
                    path: 'pages/place-save',
                    component: () => import('@/views/pages/PlaceSave'),
                },
                {
                    name: 'PlaceAccommodation',
                    path: 'pages/place-accom',
                    component: () => import('@/views/pages/PlaceAccommodation'),
                },
                {
                    name: 'PlaceBusiness',
                    path: 'pages/place-business',
                    component: () => import('@/views/pages/PlaceBusiness'),
                },
                {
                    name: 'PlaceKeyword',
                    path: 'pages/place-keyword',
                    component: () => import('@/views/pages/PlaceKeywordSearch'),
                },
                {
                    name: 'PlaceSearch',
                    path: 'pages/place-once',
                    component: () => import('@/views/pages/PlaceSearch'),
                },
                {
                    name: 'AutoCompleteKeyword',
                    path: 'pages/auto',
                    component: () => import('@/views/pages/AutoCompleteKeyword'),
                },
                {
                    name: 'RelatedKeyword',
                    path: 'pages/rel',
                    component: () => import('@/views/pages/RelatedKeyword'),
                },


                // Admin
                {
                    name: 'UserManagement',
                    path: 'admin/user',
                    component: () => import('@/views/adminPages/UserManage'),
                },
                {
                    name: 'NoticaManagement',
                    path: 'admin/notice',
                    component: () => import('@/views/adminPages/NoticeManage'),
                },
            ]
        },
        {
            path: '/login',
            name: 'Login',
            component: () => import('@/views/login/Login')
        },
        {
            path: '/register',
            name: 'Register',
            component: () => import('@/views/login/Register')
        },
        {
            path: '/404',
            name: 'NotFound',
            component: () => import('@/views/common/NotFound')
        },
        {
            path: '/kakao',
            redirect: 'https://www.naver.com'
        },
        {
            path: '*',
            redirect: '/404'
        }
    ],
})