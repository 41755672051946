<template>
  <v-navigation-drawer
    v-model="Sidebar_drawer"
    :dark="SidebarColor !== 'white'"
    :color="SidebarColor"
    mobile-breakpoint="xs"
    clipped
    :right="$vuetify.rtl"
    mini-variant-width="90"
    :expand-on-hover="expandOnHover"
    app
    id="main-sidebar"
  >
    <v-list dense nav>
      <!---USer Area -->
      <v-list-item two-line class="px-0">
        <v-list-item-content>
          <v-list-item-title>{{id}} 님 반갑습니다!</v-list-item-title>
          <v-list-item-subtitle class="">만료일 : {{useDate}}</v-list-item-subtitle>
          <v-list-item-subtitle class="">보유 슬롯 : {{useSlot}}개</v-list-item-subtitle>
          <v-list-item-subtitle class="">사용 슬롯 : {{usedSlot}}개 ( 잔여 : {{useSlot - usedSlot}}개 )</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <hr>
      <!---문의하기-->
      <v-list-item
        :active-class="`success white--text`"
        link
        @click="moveKakao">
        <v-list-item-icon>
          <v-icon>mdi-emoticon</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>문의하기</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!---Sidebar Items -->
      <hr>
      <span style="font-size:0.8em; color:#666">계정</span>
      <v-list-item
        v-for="item in itemsUser"
        :key="item.title"
        :to="item.to"
        :active-class="`success white--text`"
        @click="closeSidebar"
        link
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <hr>
      <span style="font-size:0.8em; color:#666">플레이스</span>
      <v-list-item
        v-for="item in itemsPlace"
        :key="item.title"
        :to="item.to"
        :active-class="`success white--text`"
        @click="closeSidebar"
        link
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      
      <hr v-if="itemsAdmin.length > 0">
      <span style="font-size:0.8em; color:#666" v-if="itemsAdmin.length > 0">관리자</span>
      <v-list-item
        v-for="item in itemsAdmin"
        :key="item.title"
        :to="item.to"
        :active-class="`success white--text`"
        @click="closeSidebar"
        link
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!---Sidebar Items -->
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from "vuex";
import { wrapFetch } from "../../config";

export default {
  name: "Sidebar",
  props: {
    expandOnHover: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    id: "",
    useDate: "",
    useSlot: 0,
    usedSlot: 0,
    itemsUser: [
      {
        title: "대시보드",
        icon: "mdi-home",
        to: "/pages/dashboard"
      },
      {
        title: "사용자 정보",
        icon: "mdi-account-circle",
        to: "/pages/profile"
      },
      {
        title: "그룹 관리",
        icon: "mdi-group",
        to: "/pages/group"
      },
      {
        title: "삭제 키워드 관리",
        icon: "mdi-trash-can-outline",
        to: "/pages/delete"
      },
    ],
    itemsPlace: [
      {
        title: "플레이스 순위 확인",
        icon: "mdi-database-marker-outline",
        to: "/pages/place"
      },
      {
        title: "플레이스 저장 확인",
        icon: "mdi-content-save-cog-outline",
        to: "/pages/place-save"
      },
      {
        title: "예약 팬션 순위 확인",
        icon: "mdi-hoop-house",
        to: "/pages/place-accom"
      },
      {
        title: "플레이스 순위 확인 (영업)",
        icon: "mdi-handshake",
        to: "/pages/place-business"
      },
      {
        title: "자동완성 검색",
        icon: "mdi-auto-fix",
        to: "/pages/auto"
      },
      {
        title: "연관검색어 검색",
        icon: "mdi-relative-scale",
        to: "/pages/rel"
      },
      {
        title: "플레이스 순위 1회 검색",
        icon: "mdi-crosshairs",
        to: "/pages/place-once"
      },
      // {
      //   title: "키워드 검색량 확인",
      //   icon: "mdi-file-word-box-outline",
      //   to: "/pages/place-keyword"
      // },
    ],
    itemsAdmin: []
  }),
  computed: {
    ...mapState(["SidebarColor", "SidebarBg"]),
    Sidebar_drawer: {
      get() {
        return this.$store.state.Sidebar_drawer;
      },
      set(val) {
        this.$store.commit("SET_SIDEBAR_DRAWER", val);
      }
    }
  },
  watch: {
    "$vuetify.breakpoint.smAndDown"(val) {
      this.$emit("update:expandOnHover", !val);
    }
  },
  mounted() {
    let self = this;
    wrapFetch('/user', 'GET')
    .then((response) => {
      self.id = response.session.id;
      self.useDate = response.session.useDate;
      self.useSlot = parseInt(response.session.useSlot);
      self.usedSlot = 0;
      response.slots.forEach( e => {
        self.usedSlot += parseInt(e.usedSlot);
      })
      if(response.session.authLevel === '2'){
        self.itemsAdmin.push(
        {
          title: "사용자 관리 (관리자)",
          icon: "mdi-view-dashboard",
          to: "/admin/user"
        })
        self.itemsAdmin.push(
        {
          title: "공지사항 (관리자)",
          icon: "mdi-view-dashboard",
          to: "/admin/notice"
        })
      }
    })
  },
  methods: {
    moveKakao(){
      window.open('http://pf.kakao.com/_ckiMn/chat', '_blank');
    },
    closeSidebar() {
      if (this.$vuetify.breakpoint.smAndDown) {
        this.Sidebar_drawer = false;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
#main-sidebar{
  box-shadow:1px 0 20px rgba(0,0,0,.08);
  -webkit-box-shadow:1px 0 20px rgba(0,0,0,.08);
  .v-navigation-drawer__border{
    display: none;
  }
  .v-list{
    padding: 8px 15px;
  }
  .v-list-item{
      &__icon--text,
      &__icon:first-child{
        justify-content: center;
        text-align: center;
        width: 20px;
        
      }
      
  }    
}
</style>