<template>
  <v-container fluid class="down-top-padding">
    <!-- 상단: 기본 정보 -->
    <v-row no-gutters>
      <v-col cols="12">
        <h1>대시 보드</h1>
      </v-col>
      <v-col cols="12" style="margin:20px 0;">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>가이드</v-expansion-panel-header>
            <v-expansion-panel-content>
              - 대시보드를 확인하는 화면입니다.<br>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card outlined>
          <v-card-title class="text-h5">기본 정보</v-card-title>
          <v-card-text>
            <v-row>
              <v-col>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="text-center font-weight-bold mb-1 text-h6">사용 기간</v-list-item-title>
                    <v-list-item-subtitle class="text-center font-weight-medium text-h6">{{ useDate }} 까지</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="text-center font-weight-bold mb-1 text-h6">총 슬롯</v-list-item-title>
                    <v-list-item-subtitle class="text-center font-weight-medium text-h6">{{ useSlot }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="text-center font-weight-bold mb-1 text-h6">사용 중 슬롯 </v-list-item-title>
                    <v-list-item-subtitle class="text-center font-weight-medium text-h6">{{ usedSlot }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- 중단: 슬롯 사용 정보 -->
    <v-row>
      <v-col cols="12">
        <v-card outlined>
          <v-card-title class="text-h5">슬롯 사용 정보</v-card-title>
          <v-card-text>
            <v-data-table :items="usedSlotList" :headers="slotHeaders" class="elevation-1">
              <template v-slot:item.menuName="{ item }">
                <span class="href-effect" v-if="item.placeTenant == '1'" @click="$router.push(tenantMatchedUrl.find(item => item.placeTenant === '1').url)">플레이스 순위 확인</span>
                <span class="href-effect" v-if="item.placeTenant == '2'" @click="$router.push(tenantMatchedUrl.find(item => item.placeTenant === '2').url)">플레이스 저장 확인</span>
                <span class="href-effect" v-if="item.placeTenant == '3'" @click="$router.push(tenantMatchedUrl.find(item => item.placeTenant === '3').url)">예약 펜션 순위 확인</span>
                <span class="href-effect" v-if="item.placeTenant == '4'" @click="$router.push(tenantMatchedUrl.find(item => item.placeTenant === '4').url)">플레이스 순위 확인(영업)</span>
                <span class="href-effect" v-if="item.placeTenant == '5'" @click="$router.push(tenantMatchedUrl.find(item => item.placeTenant === '5').url)">자동완성 검색</span>
                <span class="href-effect" v-if="item.placeTenant == '6'" @click="$router.push(tenantMatchedUrl.find(item => item.placeTenant === '6').url)">연관 검색어 검색</span>
                <span class="href-effect" v-if="item.placeTenant == '7'" @click="$router.push(tenantMatchedUrl.find(item => item.placeTenant === '7').url)">플레이스 순위 1회 검색</span>
                <span class="href-effect" v-if="item.placeTenant == '8'" @click="$router.push(tenantMatchedUrl.find(item => item.placeTenant === '8').url)">키워드 검색량 확인</span>
              </template>
              <template v-slot:item.usedSlot="{ item }">
                <span class="href-effect" v-if="item.placeTenant == '1'" @click="$router.push(tenantMatchedUrl.find(item => item.placeTenant === '1').url)">{{ parseInt(item.usedSlot).toLocaleString() }} 개</span>
                <span class="href-effect" v-if="item.placeTenant == '2'" @click="$router.push(tenantMatchedUrl.find(item => item.placeTenant === '2').url)">{{ parseInt(item.usedSlot).toLocaleString() }} 개</span>
                <span class="href-effect" v-if="item.placeTenant == '3'" @click="$router.push(tenantMatchedUrl.find(item => item.placeTenant === '3').url)">{{ parseInt(item.usedSlot).toLocaleString() }} 개</span>
                <span class="href-effect" v-if="item.placeTenant == '4'" @click="$router.push(tenantMatchedUrl.find(item => item.placeTenant === '4').url)">{{ parseInt(item.usedSlot).toLocaleString() }} 개</span>
                <span class="href-effect" v-if="item.placeTenant == '5'" @click="$router.push(tenantMatchedUrl.find(item => item.placeTenant === '5').url)">{{ parseInt(item.usedSlot).toLocaleString() }} 개</span>
                <span class="href-effect" v-if="item.placeTenant == '6'" @click="$router.push(tenantMatchedUrl.find(item => item.placeTenant === '6').url)">{{ parseInt(item.usedSlot).toLocaleString() }} 개</span>
                <span class="href-effect" v-if="item.placeTenant == '7'" @click="$router.push(tenantMatchedUrl.find(item => item.placeTenant === '7').url)">{{ parseInt(item.usedSlot).toLocaleString() }} 개</span>
                <span class="href-effect" v-if="item.placeTenant == '8'" @click="$router.push(tenantMatchedUrl.find(item => item.placeTenant === '8').url)">{{ parseInt(item.usedSlot).toLocaleString() }} 개</span>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- 하단: 공지사항 -->
    <v-row>
      <v-col cols="12">
        <v-card outlined>
          <v-card-title class="text-h5">공지사항</v-card-title>
          <v-card-text>
            <v-list>
              <v-list-item
                  v-for="(notice, index) in notices"
                  :key="index"
                  @click="openNoticeDialog(notice)"
                  style="border-bottom: 1px solid lightgray;
              ">
                <v-list-item-content class="my-1">
                  <v-list-item-title class="font-weight-bold">{{ notice.title }}</v-list-item-title>
                  <v-list-item-subtitle>{{ notice.createTime }} | 작성자 : 관리자</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- 공지사항 상세보기 Dialog -->
    <v-dialog v-model="dialog" max-width="1024px">
      <v-card>
        <v-card-title style="border-bottom: 0.5px solid lightgray;">
          <v-row class="justify-space-between">
            <v-col cols="6"> {{ selectedNotice.title }} </v-col>
            <v-col cols="6" class="text-right"> <v-btn color="primary" text @click="dialog = false"> <v-icon>mdi-close</v-icon></v-btn>
            </v-col>
          </v-row>
            </v-card-title>
        <div style="height:15px;"></div>
        <v-card-text v-html="selectedNotice.content"></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { wrapFetch } from '../../config';
export default {
  data() {
    return {
      slotHeaders: [
        { text: 'ID', value: 'placeTenant' },
        { text: '메뉴 명', value: 'menuName' },
        { text: '사용 개수', value: 'usedSlot' },
      ],
      notices: [
        { title: '공지사항 1', date: '2023-10-01', content: '공지사항 1의 상세 내용' },
        { title: '공지사항 2', date: '2023-10-05', content: '공지사항 2의 상세 내용' },
        // 추가 공지사항
      ],
      dialog: false,
      selectedNotice: {},
      id: '',
      useDate: '',
      useSlot: 0,
      usedSlot: 0,
      usedSlotList: [],
      tenantMatchedUrl : [
        {placeTenant: '1', url: '/pages/place' },
        {placeTenant: '2', url: '/pages/place-save' },
        {placeTenant: '3', url: '/pages/place-accom'},
        {placeTenant: '4', url: '/pages/place-business' },
        {placeTenant: '5', url: '/pages/auto' },
        {placeTenant: '6', url: '/pages/rel' },
        {placeTenant: '7', url: '/pages/place-once' },
        {placeTenant: '8', url: '/pages/dashboard' },
      ]
    };
  },
  methods: {
    openNoticeDialog(notice) {
      this.selectedNotice = notice;
      this.dialog = true;
    },
  },
  mounted(){
    let self = this;
    wrapFetch('/user', 'GET')
    .then((response) => {
      self.id = response.session.id;
      self.useDate = response.session.useDate;
      self.useSlot = parseInt(response.session.useSlot);
      self.usedSlot = 0;
      self.usedSlotList = response.slots;
      response.slots.forEach( e => {
        self.usedSlot += parseInt(e.usedSlot);
      })
      if(response.session.authLevel === '2'){
        self.itemsAdmin.push(
        {
          title: "사용자 관리 (관리자)",
          icon: "mdi-view-dashboard",
          to: "/admin/user"
        })
      }
    });
    self.notices = [];
    wrapFetch('/notice?searchText=', 'GET')
    .then((response) => {
      self.notices.push(...response.data)
    })
  }
};
</script>

<style scoped>
.href-effect:hover {
  color: #0056b3;
  cursor: pointer
}
</style>